<template>
    <div
        class="relative flex justify-center pt-[70px] sm:h-[555px] sm:pt-[80px]"
    >
        <div
            v-if="attributes.length > 0"
            class="absolute left-[65px] top-0 sm:left-[130px] sm:top-[0px]"
        >
            <AttributeButton
                class="z-20"
                :selected="number === 1"
                @click="selectNumber(1)"
            />
            <svg height="180" width="500" class="absolute left-0 top-0">
                <polyline
                    points="23,50 23,150"
                    class="fill-transparent stroke-grind stroke-2"
                />
            </svg>
        </div>
        <div
            v-if="attributes.length > 1"
            class="absolute right-[-70px] top-[90px] sm:right-[-90px] sm:top-[130px]"
        >
            <AttributeButton
                class="z-20"
                :selected="number === 2"
                @click="selectNumber(2)"
            />
            <svg height="150" width="300" class="absolute bottom-0 right-0 z-0">
                <polyline
                    points="120,127 250,127"
                    class="hidden fill-transparent stroke-grind stroke-2 sm:block"
                />
                <polyline
                    points="200,127 250,127"
                    class="fill-transparent stroke-grind stroke-2 sm:hidden"
                />
            </svg>
        </div>
        <div
            v-if="attributes.length > 2"
            class="absolute bottom-[-60px] right-[-60px] sm:bottom-[-45px] sm:right-[-60px]"
        >
            <AttributeButton
                class="z-20"
                :selected="number === 3"
                @click="selectNumber(3)"
            />
            <svg height="150" width="300" class="absolute bottom-0 right-0 z-0">
                <polyline
                    points="280,100 280,50 200,50"
                    class="fill-transparent stroke-grind stroke-2"
                />
            </svg>
        </div>
        <div
            v-if="attributes.length > 3"
            class="absolute bottom-[60px] left-[-70px] sm:bottom-[110px] sm:left-[-80px]"
        >
            <AttributeButton
                class="z-20"
                :selected="number === 4"
                @click="selectNumber(4)"
            />
            <svg height="150" width="300" class="absolute left-0 top-0 z-0">
                <polyline
                    points="120,23 50,23"
                    class="fill-transparent stroke-grind stroke-2"
                />
            </svg>
        </div>
        <div
            v-if="attributes.length > 4"
            class="absolute left-[-50px] top-[50px] sm:left-[-70px] sm:top-[130px]"
        >
            <AttributeButton
                class="z-20"
                :selected="number === 5"
                @click="selectNumber(5)"
            />
            <svg height="150" width="300" class="absolute left-0 top-0 z-0">
                <polyline
                    points="23,50 23,100, 100,100"
                    class="fill-transparent stroke-grind stroke-2"
                />
            </svg>
        </div>
        <img
            v-if="image"
            class="z-10 h-[265px] w-[175px] bg-transparent sm:h-[475px] sm:w-[314px]"
            :src="image.src"
            :alt="image.alt"
        />
    </div>
</template>

<script setup lang="ts">
import ProductAnatomyAttributeType from '~/types/ProductAnatomyAttributeType';
import ImageType from '~/types/ImageType';
import AttributeButton from '~/components/page-building/product-anatomy/components/AttributeButton.vue';

const emit = defineEmits(['setNumber']);

const selectNumber = (number: number) => {
    emit('setNumber', number);
};

defineProps<{
    image: ImageType;
    attributes: ProductAnatomyAttributeType[];
    number: number;
}>();
</script>
